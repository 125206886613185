<template>
  <div class="goodlist">
    <el-container id="el-container">
      <my-header></my-header>
      <el-main id="el-main">
        <div class="header">我的足迹</div>
        <div class="goodlist-box" v-if="Object.keys(GoodByList).length">
          <div class="goodlist-list" v-for="(v, k) in GoodByList" :key="k">
            <div>{{ k }}</div>
            <div class="GoodByList">
              <div
                class="goodlist-list-box"
                @click="toMessage(item)"
                v-for="(item, index8) in GoodByList[k]"
                :key="index8"
              >
                <div class="goodlist-list-box-box">
                  <div class="goodlist-include-box">
                    <img
                      class="goodlist-list-img"
                      :src="item.mainImage"
                      alt=""
                    />
                  </div>
                  <p class="goodlist-list-txt">{{ item.productName }}</p>
                  <div class="price-row">
                    <span class="price-a">￥</span>
                    <span class="price-b">{{ item.price }}</span>
                    <span class="price-a">.00</span>
                  </div>
                  <div class="goodlist-bottom" @click.stop="toMessage(item)">
                    <div class="goodlist-bottom-left">
                      <span>查看详情</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="!time" class="pagenavation">
            <span>加载更多...</span>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      GoodByList: {},
      pageNum: 1,
      pageSize: 10,
      // 定时器
      time: true,
    };
  },
  methods: {
    async getbrowsingHistory() {
      const res = await this.$http({
        url: "front/browsingHistory/findBrowsingHistory",
        method: "get",
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      });
      if (res.data.status == 200) {
        await res.data.data.list.forEach((item) => {
          let updateTime = item.updateTime.split(" ")[0];
          if (updateTime in this.GoodByList) {
            this.GoodByList[updateTime].push(item);
          } else {
            this.$set(this.GoodByList, updateTime, []);
            this.GoodByList[updateTime].push(item);
          }
        });
        this.time = true;
        console.log(this.GoodByList);
      }
    },
    async toMessage(item) {
      const res = await this.$http({
        method: "get",
        url: "front/product/findById?id=" + item.productId,
      });
      if (res.data.data) {
        this.$router.push({
          path: "/goodMessage",
          query: { name: JSON.stringify(res.data.data) },
        });
      }
    },
    handleScroll() {
      if (this.time) {
        let app = document.getElementById("app");
        let scrollTop = this.$parent.$el.scrollTop; //表示获取router-view上一级的scrollTop
        console.log(document.getElementById("app").scrollTop);
        console.log(document.getElementById("app").scrollHeight);
        if (scrollTop > 550) {
          this.pageNum += 1;
          this.time = false;
          this.getbrowsingHistory();
        } else {
        }
      }
    },
  },
  created() {
    this.getbrowsingHistory();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.header {
  height: 100px;
  background: #00c65b;
  color: #fff;
  line-height: 120px;
  font-weight: 700;
  font-size: 24px;
  padding: 0px 50px;
}
#el-container {
  background-color: #f4f4f4;
  /* height: 8000px; */
}
#el-main {
  overflow-y: hidden;
  width: 100%;
  min-width: 1000px;
  padding: 0 0;
  position: relative;
  margin-top: 140px;
}
.goodlist-box {
  width: 100%;
  height: 100%;
}
.goodlist-list {
  width: 77%;
  min-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  & > div:nth-child(1) {
    height: 45px;
    line-height: 45px;
    font-size: 25px;
    font-weight: 700;
  }
}
.GoodByList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.goodlist-list-box {
  width: 25%;
}
.goodlist-list-box-box {
  width: 85%;
  margin: 10px;
  padding: 10px;
  background: #fff;
  border-radius: 6px;
}
.goodlist-list-box-box:hover {
  box-shadow: darkgrey 0px 2px 10px 1px;
}
.goodlist-include-box {
  width: 80%;
  height: 160px;
  margin: 0 auto;
  margin-top: 5%;
}
.goodlist-list-img {
  width: 100%;
  height: 180px;
  border-radius: 5px;
}
.goodlist-list-txt {
  width: 90%;
  margin: auto;
  font-size: 13px;
  margin-top: 35px;
  margin-left: 15px;
}
.goodlist-list-txt:hover p {
  color: rgb(123, 123, 224);
}
.price-row {
  margin-top: 12px;
  margin-left: 5px;
}
.price-row span {
  color: #e4393c;
  font-size: 20px;
}
.goodlist-bottom {
  margin: 0 auto;
  display: flex;
  width: 70%;
  height: 50px;
  margin-top: 15px;
}
.goodlist-bottom:hover {
  border-radius: 4px;
  transform: scale(1.02);
}
.goodlist-bottom-left {
  border-radius: 4px;
  width: 1000%;
  height: 100%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  background: #e1251b;
}
.goodlist-bottom-left span {
  color: #fff;
}
.goodlist-bottom-left span {
  line-height: 50px;
}
.pagenavation {
  line-height: 50px;
  text-align: center;
}
</style>